// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div style="width: 100%;">
    <!-- Edit Tag Modal -->
    <a-modal
      :title="$t('label.edit.licenseTag') + ' - ' + this.editLicenseLabel"
      @ok="submitEditTag()"
      @cancel="closeAction('edit')"
      okText="OK"
      cancelText="Cancel"
      :visible="showEditTagModal"
      :closable="false"
      :maskClosable="false"
      centered>
      <a-form
        v-ctrl-enter="submitEditTag"
        :ref="formRefEdit"
        :model="formEdit"
        :rules="rulesEdit"
        @finish="submitEditTag"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item ref="tagPrice" name="tagPrice" :label="$t('label.licence.costs')">
            <a-input-number
              v-model:value="formEdit.tagPrice"
              style="margin-top: 2px; width: 150px;"
              :placeholder="'Price per Licence'"
              :min="0" /> €
          </a-form-item>
          <div>
            <a-row :gutter="12">
              <a-col :md="24" :lg="12">
                <a-form-item ref="woodPlan" name="woodPlan">
                  <span>{{ $t('label.wood')}}</span><br/>
                  <a-input-number
                    v-model:value="formEdit.woodPlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.wood') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item ref="bronzePlan" name="bronzePlan">
                  <span>{{ $t('label.bronze') }}</span><br/>
                  <a-input-number
                    v-model:value="formEdit.bronzePlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.bronze') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item ref="silverPlan" name="silverPlan">
                  <span>{{ $t('label.silver') }}</span><br/>
                  <a-input-number
                    v-model:value="formEdit.silverPlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.silver') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item ref="goldPlan" name="goldPlan">
                  <span>{{ $t('label.gold') }}</span><br/>
                  <a-input-number
                    v-model:value="formEdit.goldPlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.gold') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item ref="platinPlan" name="platinPlan">
                  <span>{{ $t('label.platin') }}</span><br/>
                  <a-input-number
                    v-model:value="formEdit.platinPlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.platin') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <br>
          <span style="font-weight: bold; font-size: 18px;">{{'Partner-Portal &nbsp;'+ this.$t('label.params')}}</span>
          <br>
          <a-form-item ref="showInPartnerPortal" name="showInPartnerPortal">
            <template #label>
              <tooltip-label :title="$t('label.showInPartnerPortal')" :tooltip="$t('label.showInPartnerPortal')"/>
            </template>
            <a-switch v-model:checked="formEdit.showInPartnerPortal" @change="checkForIsCheckbox('edit', $event)"></a-switch>
          </a-form-item>
          <div v-if="this.formEdit.showInPartnerPortal === true">
            <a-form-item ref="tagLabel" name="tagLabel">
              <template #label>
                <span class="popoverLabel">{{ $t('label.taglabel') }}</span>
                  <span>
                    <a-popover :title="'Generelle Label, welche zur Darstellung der Lizenz im Partner Portal angezeigt werden.'" :placement="'right'">
                      <info-circle-outlined class="tooltip-icon" />
                      <template #content>
                        <div style="white-space: pre;">{{ 'Als Default-Wert dient der Name der Lizenz!' }}</div>
                        <img src="../../assets/tooltip-descriptions/label.png" height="500" width="200">
                      </template>
                    </a-popover>
                  </span>
                </template>
                <a-input v-model:value="formEdit.tagLabel" :placeholder="$t('label.taglabel')"></a-input>
              </a-form-item>
              <a-form-item ref="hoverLabel" name="hoverLabel">
                <template #label>
                  <span class="popoverLabel">{{ $t('label.hoverlabel') }}</span>
                  <span>
                    <a-popover :title="'Angezeigtes Label, wenn man über die einzelnen Produkte in der Preisübersicht hovert.'" :placement="'right'">
                      <info-circle-outlined class="tooltip-icon" />
                      <template #content>
                        <div style="white-space: pre;">{{ 'Als Default-Wert dient der Name der Lizenz!' }}</div>
                        <img src="../../assets/tooltip-descriptions/hoverLabel.png">
                      </template>
                    </a-popover>
                  </span>
                </template>
              <a-input v-model:value="formEdit.hoverLabel" :placeholder="$t('label.hoverlabel')"></a-input>
            </a-form-item>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-form-item ref="isCheckbox" name="isCheckbox">
                <template #label>
                  <span class="popoverLabel">{{ $t('label.isCheckbox') }}</span>
                  <span>
                    <a-popover :title="'Soll die erstellte Lizenz als Switch dargestellt werden?'" :placement="'bottom'">
                      <info-circle-outlined class="tooltip-icon" />
                      <template #content>
                        <div style="white-space: pre;">{{ 'Dieses Attribut ist für Lizenzen mit dem Typ "License per Core" automatisch aktiviert!\nInputs für Lizenzmengen werden standardmäßg als Inputfeld dargestellt. Das Aktivieren dieser Funktion stellt den Input für die Mengenangabe dieser Lizenz als Switch dar.\nSomit wird in diesem Fall nur aktiv/inaktiv dargestellt.\nBeispiele hierfür sind:\n- Lizenzen per Core\n- Lizenzen, die nur einmal Pro VM/Setup verwendet werden können (z.B. GPUs, NGF, etc.)' }}</div>
                        <img src="../../assets/tooltip-descriptions/isCheckbox.png">
                      </template>
                    </a-popover>
                  </span>
                </template>
                <a-switch v-model:checked="formEdit.isCheckbox" :disabled="(preFillEditLicenseType === 'Software per Core' || preFillEditLicenseType === 'Hardware') ? true : false"></a-switch>
              </a-form-item>
              <!-- <a-form-item ref="order" name="order">
                <template #label>
                  <tooltip-label :title="$t('label.order')" :tooltip="$t('label.order.tooltip')"/>
                </template>
                <a-input type="number" v-model:value="formEdit.order"></a-input>
              </a-form-item> -->
            </div>
            <a-form-item ref="popover" name="popover">
              <template #label>
                <span class="popoverLabel">{{ $t('label.popover') }}</span>
                <span>
                  <a-popover :title="'Benötigt die Lizenz Zusatzinformationen als Popover?'" :placement="'bottom'">
                    <info-circle-outlined class="tooltip-icon" />
                    <template #content>
                      <div style="white-space: pre;">{{ 'Lizenzen mit diesem Attribut erhalten ein "Info-Icon" hinter ihrem Label. Beim Hovern über dieses Icon werden zusätzlichen Informationen in Form eines Popovers angezeigt\nEs muss ein Titel und eine Beschreibung (content) angegeben werden!' }}</div>
                      <img src="../../assets/tooltip-descriptions/popover.png">
                    </template>
                  </a-popover>
                </span>
              </template>
              <a-switch v-model:checked="formEdit.popover" @change="setPopoverData('edit', $event)"></a-switch>
              <div v-if="this.formEdit.popover === true">
                <a-form-item ref="popoverTitle" name="popoverTitle" :label="$t('label.popover.title')">
                  <a-input :placeholder="$t('label.popover.title')" v-model:value="formEdit.popoverTitle" style="margin: 3px 0"></a-input>
                </a-form-item>
                <a-form-item ref="popoverContent" name="popoverContent" :label="$t('label.popover.content')">
                  <a-input :placeholder="$t('label.popover.content')" v-model:value="formEdit.popoverContent"></a-input>
                </a-form-item>
              </div>
            </a-form-item>
          </div>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Create Tag Modal -->
    <a-modal
      :title="$t('label.create.licenseTag')"
      @ok="submitCreateNewTag()"
      @cancel="closeAction('create')"
      okText="OK"
      cancelText="Cancel"
      :visible="showAddTagModal"
      :closable="false"
      :maskClosable="false"
      centered>
      <a-form
        v-ctrl-enter="submitCreateNewTag"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitCreateNewTag"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item ref="tagName" name="tagName" :label="$t('label.licence.label')">
            <a-input
              v-model:value="form.tagName"
              style="margin-top: 5px;"
              :placeholder="'Tag Name'"
              @change="setPartnerLabels('add', $event)" />
          </a-form-item>
          <span>{{ isPlanLicense ? $t('label.partnerDiscounts.disable') : $t('label.partnerDiscounts.enable') }} <a-switch v-model:checked="isPlanLicense"/></span>
          <a-form-item ref="tagPrice" name="tagPrice" :label="$t('label.licence.costs')">
            <a-input-number
              v-model:value="form.tagPrice"
              style="margin-top: 2px; width: 150px;"
              :placeholder="'Price per Licence'"
              :min="0" /> €
          </a-form-item>
          <div v-if="isPlanLicense">
            <a-row :gutter="12">
              <a-col :md="24" :lg="12">
                <a-form-item ref="woodPlan" name="woodPlan">
                  <span>{{ $t('label.wood')}}</span><br/>
                  <a-input-number
                    v-model:value="form.woodPlan"
                    style="margin-top: 2px; width: 200px;"
                    :placeholder="$t('label.wood') + ' ' + $t('label.discount')"
                    :min="0"
                    :max="100" /> %
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
          <a-form-item ref="bronzePlan" name="bronzePlan">
            <span>{{ $t('label.bronze') }}</span><br/>
            <a-input-number
              v-model:value="form.bronzePlan"
              style="margin-top: 2px; width: 200px;"
              :placeholder="$t('label.bronze') + ' ' + $t('label.discount')"
              :min="0"
              :max="100" /> %
          </a-form-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-item ref="silverPlan" name="silverPlan">
            <span>{{ $t('label.silver') }}</span><br/>
            <a-input-number
              v-model:value="form.silverPlan"
              style="margin-top: 2px; width: 200px;"
              :placeholder="$t('label.silver') + ' ' + $t('label.discount')"
              :min="0"
              :max="100" /> %
          </a-form-item>
        </a-col>
      <a-col :md="24" :lg="12">
          <a-form-item ref="goldPlan" name="goldPlan">
            <span>{{ $t('label.gold') }}</span><br/>
            <a-input-number
              v-model:value="form.goldPlan"
              style="margin-top: 2px; width: 200px;"
              :placeholder="$t('label.gold') + ' ' + $t('label.discount')"
              :min="0"
              :max="100" /> %
          </a-form-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-item ref="platinPlan" name="platinPlan">
            <span>{{ $t('label.platin') }}</span><br/>
            <a-input-number
              v-model:value="form.platinPlan"
              style="margin-top: 2px; width: 200px;"
              :placeholder="$t('label.platin') + ' ' + $t('label.discount')"
              :min="0"
              :max="100" /> %
          </a-form-item>
        </a-col>
        </a-row>
            Test
          </div>
          <a-form-item ref="tagType" name="tagType" :label="$t('label.type')">
            <a-select
              v-model:value="form.tagType"
              style="margin-top: 2px;"
              placeholder="Tag Type"
              @change="checkForIsCheckbox('add', $event)"
            >
              <a-select-option v-for="tagType in tagTypes" :key="tagType">
                {{ tagType }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item ref="tagColor" name="tagColor" :label="$t('label.license.color')">
            <!-- <a-input
              style="margin-top: 2px;"
              v-decorator="['tagColor', {
                rules: [{ required: true, message: `${$t('message.error.required.input')}` }]}]"
              :placeholder="'Tag Color'"
              list="colors" />
            <datalist id="colors">
              <option v-for="tagColor in uniqueTagColors" :key="tagColor" :style="{color: tagColor}"> {{ tagColor }}</option>
            </datalist> -->
            <a-select
              v-model:value="form.tagColor"
              style="margin-top: 2px;"
              placeholder="Tag Color"
            >
              <a-select-option v-for="tagColor in uniqueTagColors" :key="tagColor">
                <a-tag :color="tagColor">{{ tagColor }}</a-tag>
              </a-select-option>
            </a-select>
          </a-form-item>
          <div>
            <a-input
              style="margin-top: 20px;"
              id="newColor"
              :placeholder="'Input new Tag Color for the list'"/>
            <a-button type="primary" shape="round" @click="addItem()" style="margin: 5px 5px 0 0;"><PlusOutlined/>{{ 'Add Color to Selection' }}</a-button>
            <span>{{ '#XXXXXX Format' }}</span>
          </div>
          <br>
          <span style="font-weight: bold; font-size: 18px;">{{'Partner-Portal &nbsp;'+ this.$t('label.params')}}</span>
          <br>
          <a-form-item ref="showInPartnerPortal" name="showInPartnerPortal">
            <template #label>
              <tooltip-label :title="$t('label.showInPartnerPortal')" :tooltip="$t('label.showInPartnerPortal.tooltip')"/>
            </template>
            <a-switch v-model:checked="form.showInPartnerPortal" @change="setPartnerLabels('add', $event)"></a-switch>
          </a-form-item>
          <div v-if="this.form.showInPartnerPortal === true">
            <a-form-item ref="tagLabel" name="tagLabel">
              <template #label>
                <span class="popoverLabel">{{ $t('label.taglabel') }}</span>
                <span>
                  <a-popover :title="'Generelle Label, welche zur Darstellung der Lizenz im Partner Portal angezeigt werden.'" :placement="'right'">
                    <info-circle-outlined class="tooltip-icon" />
                    <template #content>
                      <div style="white-space: pre;">{{ 'Als Default-Wert dient der Name der Lizenz!' }}</div>
                      <img src="../../assets/tooltip-descriptions/label.png" height="500" width="200">
                    </template>
                  </a-popover>
                </span>
              </template>
                <a-input v-model:value="form.tagLabel"></a-input>
              </a-form-item>
              <a-form-item ref="hoverLabel" name="hoverLabel">
                <template #label>
                  <span class="popoverLabel">{{ $t('label.hoverlabel') }}</span>
                  <span>
                    <a-popover :title="'Angezeigtes Label, wenn man über die einzelnen Produkte in der Preisübersicht hovert.'" :placement="'right'">
                      <info-circle-outlined class="tooltip-icon" />
                      <template #content>
                        <div style="white-space: pre;">{{ 'Als Default-Wert dient der Name der Lizenz!' }}</div>
                        <img src="../../assets/tooltip-descriptions/hoverLabel.png">
                      </template>
                    </a-popover>
                  </span>
                </template>
              <a-input v-model:value="form.hoverLabel"></a-input>
            </a-form-item>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-form-item ref="isCheckbox" name="isCheckbox">
                <template #label>
                  <span class="popoverLabel">{{ $t('label.isCheckbox') }}</span>
                  <span>
                    <a-popover :title="'Soll die erstellte Lizenz als Switch dargestellt werden?'" :placement="'bottom'">
                      <info-circle-outlined class="tooltip-icon" />
                      <template #content>
                        <div style="white-space: pre;">{{ 'Dieses Attribut ist für Lizenzen mit dem Typ "License per Core" automatisch aktiviert!\nInputs für Lizenzmengen werden standardmäßg als Inputfeld dargestellt. Das Aktivieren dieser Funktion stellt den Input für die Mengenangabe dieser Lizenz als Switch dar.\nSomit wird in diesem Fall nur aktiv/inaktiv dargestellt.\nBeispiele hierfür sind:\n- Lizenzen per Core\n- Lizenzen, die nur einmal Pro VM/Setup verwendet werden können (z.B. GPUs, NGF, etc.)\nWenn also vor Allem neue GPUs hinzugefügt werden, dieses Attribut AUF JEDEN FALL setzen!' }}</div>
                        <img src="../../assets/tooltip-descriptions/isCheckbox.png">
                      </template>
                    </a-popover>
                  </span>
                </template>
                <a-switch v-model:checked="form.isCheckbox" :disabled="(form.tagType === 'Software per Core' || form.tagType === 'Hardware') ? true : false"></a-switch>
              </a-form-item>
              <!-- <a-form-item ref="order" name="order">
                <template #label>
                  <tooltip-label :title="$t('label.order')" :tooltip="$t('label.order.tooltip')"/>
                </template>
                <a-input type="number" v-model:value="form.order"></a-input>
              </a-form-item> -->
            </div>
            <a-form-item ref="popover" name="popover">
              <template #label>
                <span class="popoverLabel">{{ $t('label.popover') }}</span>
                <span>
                  <a-popover :title="'Benötigt die Lizenz Zusatzinformationen als Popover?'" :placement="'bottom'">
                    <info-circle-outlined class="tooltip-icon" />
                    <template #content>
                      <div style="white-space: pre;">{{ 'Lizenzen mit diesem Attribut erhalten ein "Info-Icon" hinter ihrem Label. Beim Hovern über dieses Icon werden zusätzlichen Informationen in Form eines Popovers angezeigt\nEs muss ein Titel und eine Beschreibung (content) angegeben werden!\n- Titel: max. 50 Zeichen\n- Content: max. 200 Zeichen!' }}</div>
                      <img src="../../assets/tooltip-descriptions/popover.png">
                    </template>
                  </a-popover>
                </span>
              </template>
              <a-switch v-model:checked="form.popover" @change="setPopoverData('add', $event)"></a-switch>
              <div v-if="this.form.popover === true">
                <a-form-item ref="popoverTitle" name="popoverTitle" :label="$t('label.popover.title')">
                  <a-input :placeholder="$t('label.popover.title')" v-model:value="form.popoverTitle" style="margin: 3px 0"></a-input>
                </a-form-item>
                <a-form-item ref="popoverContent" name="popoverContent" :label="$t('label.popover.content')">
                  <a-input :placeholder="$t('label.popover.content')" v-model:value="form.popoverContent"></a-input>
                </a-form-item>
              </div>
            </a-form-item>
          </div>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Confirmation Modal -->
      <a-modal
        style="top: 20px;"
        centered
        :title="deleteLicenseName + ' wirklich entfernen?'"
        :closable="false"
        :maskClosable="false"
        @ok="deleteLicenseTag()"
        @cancel="closeAction('delete')"
        okText="OK"
        cancelText="Cancel"
        v-model:visible="confirmationModal"
      >
        <ExclamationCircleOutlined style="color: red; font-size: 24px;"/>
        <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
      </a-modal>
      <!-- Delete Single Licenseplan Confirmation Modal -->
      <a-modal
        style="top: 20px;"
        centered
        :title="deleteSinglePlanName + '-Plan Rabatt für Lizenz ' + deleteSinglePlanLicenseName + ' wirklich entfernen?'"
        :closable="false"
        :maskClosable="false"
        @ok="submitDeleteSingleLicensePlan()"
        @cancel="closeAction('singleDelete')"
        okText="OK"
        cancelText="Cancel"
        v-model:visible="deleteSinglePlanconfirmationModal"
      >
        <ExclamationCircleOutlined style="color: red; font-size: 24px;"/>
        <p style="margin-top: 20px;"> {{ 'Möchten Sie den ' + deleteSinglePlanName + '-Plan Rabatt für die Lizenz ' + deleteSinglePlanLicenseName + ' wirklich entfernen?' }}</p>
      </a-modal>
    <!-- Start of Table /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <a-card>
      <a-button type="primary" shape="round" @click="showAddTagModal = true" style="margin-bottom: 20px;"><PlusOutlined/>{{ $t('label.create.licenseTag') }}</a-button>
      <a-table :columns="columns" rowKey="id" :data-source="items" :pagination="false" size="small">
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              <template #icon><SearchOutlined /></template>
              Search
            </a-button>
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
              Reset
            </a-button>
          </div>
        </template>
        <template #filterIcon="filtered">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #tagName="{ record }">
          <a-tag :color="record.tagColor">{{ record.tagText }}</a-tag>
        </template>
        <template #price="{ text }">
          <span>{{ text + ' €' }}</span>
        </template>
        <template #planDiscounts="{ record }">
          <div v-if="record.planDiscounts.length !== 0">
            <!-- add labels with colors for each plan -->
            <span v-for="plan in record.planDiscounts" :key="plan.Id">
              <a-tag v-if="plan.plan === 'Holz'" :color="'#9a7b4f'" :closable="true" @close="onDeleteSingleLicensePlan(record.tagText, plan.dbTableId, plan.plan)">{{ plan.plan + ' - ' + parseFloat(plan.discount) + ' %' }}</a-tag>
              <a-tag v-if="plan.plan === 'Bronze'" :color="'#cd7f32'" :closable="true" @close="onDeleteSingleLicensePlan(record.tagText, plan.dbTableId, plan.plan)">{{ plan.plan + ' - ' + parseFloat(plan.discount) + ' %' }}</a-tag>
              <a-tag v-if="plan.plan === 'Silber'" :color="'#afafaf'" :closable="true" @close="onDeleteSingleLicensePlan(record.tagText, plan.dbTableId, plan.plan)">{{ plan.plan + ' - ' + parseFloat(plan.discount) + ' %' }}</a-tag>
              <a-tag v-if="plan.plan === 'Gold'" :color="'#edc006'" :closable="true" @close="onDeleteSingleLicensePlan(record.tagText, plan.dbTableId, plan.plan)">{{ plan.plan + ' - ' + parseFloat(plan.discount) + ' %' }}</a-tag>
              <a-tag v-if="plan.plan === 'Platin'" :color="'#a0b2c6'" :closable="true" @close="onDeleteSingleLicensePlan(record.tagText, plan.dbTableId, plan.plan)">{{ plan.plan + ' - ' + parseFloat(plan.discount) + ' %' }}</a-tag>
            </span>
            <!-- <span v-for="plan in record.planDiscounts" :key="plan.Id">{{ plan.plan + ' - ' + plan.discount + ' %' }}</span> -->
          </div>
        </template>
        <template #showOnPartner="{ record }">
          <CheckSquareFilled v-if="record.showOnPartner === true" style="font-size:25px; color: rgb( 66, 220, 115 )"/>
          <CloseSquareFilled v-if="record.showOnPartner === false" style="font-size:25px; color: rgb( 255, 87, 51)"/>
        </template>
        <template #delete="{ record }">
          <a-button v-if="record.tagText !== 'NoBill'" type="default" shape="circle" @click="onEditLicense(record)" style="margin-right: 5px;"><SettingOutlined/></a-button>
          <a-button v-if="record.tagText !== 'NoBill'" type="primary" danger shape="circle" @click="onDeleteLicense(record)"><DeleteOutlined/></a-button>
        </template>
        <template #customRender="{text, column}">
          <span v-if="state.searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${state.searchText})|(?=${state.searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === state.searchText.toLowerCase()"
                :key="i"
                class="highlight">
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import ListView from '@/components/view/ListView'
import wpapi from '@/wpApi/myApi'
import api2 from '@/wpApi/api2'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'QuotaSummary',
  components: {
    ListView,
    TooltipLabel
  },
  data () {
    return {
      confirmationModal: false,
      deleteSinglePlanconfirmationModal: false,
      confirmationMessage: '',
      deleteLicenseName: undefined,
      deleteLicenseType: undefined,
      deleteLicenseId: undefined,
      deleteSinglePlanLicenseName: undefined,
      deleteSinglePlanName: undefined,
      deleteSinglePlanId: undefined,
      deleteLicensePlanIdList: [],
      isPlanLicense: false,
      licensePlanDiscounts: [],
      tagTypes: [],
      showAddTagModal: false,
      showEditTagModal: false,
      loading: false,
      items: [],
      planData: [],
      preFillEditLicenseId: undefined,
      preFillEditLicenseColor: undefined,
      preFillEditLicenseType: undefined,
      preFillEditShowInPartnerPortal: undefined,
      preFillEditTagLabel: undefined,
      preFillEditHoverLabel: undefined,
      preFillEditIsCheckbox: undefined,
      preFillEditPopover: undefined,
      preFillEditPopoverContent: undefined,
      preFillEditPopoverTitle: undefined,
      editLicenseLabel: undefined,
      uniqueTagColors: [],
      uniqueTagTypes: [],
      searchedColumn: '',
      columns: [
        {
          title: this.$t('label.label'),
          key: 'tagName',
          dataIndex: 'tagName',
          width: '20%',
          slots: {
            customRender: 'tagName',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon'
          },
          onFilter: (value, record) =>
            record.tagText
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 0)
            }
          }
        },
        {
          key: 'tagType',
          title: this.$t('label.type'),
          dataIndex: 'tagType',
          slots: { customRender: 'tagType' }
        },
        {
          title: this.$t('label.licence.costs'),
          dataIndex: 'price',
          slots: { customRender: 'price' }
        },
        {
          title: this.$t('label.partnerDiscounts'),
          dataIndex: 'planDiscounts',
          slots: { customRender: 'planDiscounts' }
        },
        {
          title: this.$t('label.license.visible'),
          dataIndex: 'showOnPartner',
          slots: { customRender: 'showOnPartner' }
        },
        {
          title: 'Edit Tag',
          dataIndex: 'delete',
          slots: { customRender: 'delete' },
          width: '16%'
        }
      ]
    }
  },
  provide: function () {
    return {
      parentFetchData: this.fetchData,
      parentToggleLoading: () => { this.loading = !this.loading },
      parentEditTariffAction: []
    }
  },
  created () {
    this.initForm()
    if (!['Admin'].includes(this.$store.getters.userInfo.roletype)) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  mounted () {
    this.fetchPlanData()
    this.fetchData()
  },
  methods: {
    initForm () {
      this.searchInput = ref()
      this.state = reactive({
        searchText: '',
        searchedColumn: ''
      })
      this.formRef = ref()
      this.formRefEdit = ref()
      this.form = reactive({
        tagName: '',
        tagType: undefined,
        tagPrice: '',
        tagColor: undefined,
        woodPlan: undefined,
        bronzePlan: undefined,
        silverPlan: undefined,
        goldPlan: undefined,
        platinPlan: undefined,
        showInPartnerPortal: false,
        tagLabel: undefined,
        hoverLabel: undefined,
        isCheckbox: false,
        order: 0,
        popover: false,
        popoverTitle: undefined,
        popoverContent: undefined
      })
      this.rules = reactive({
        tagName: [{ required: true, message: `${this.$t('message.error.required.input')}` }],
        tagPrice: [{ required: true, message: `${this.$t('message.error.required.input')}` }],
        tagType: [{ required: true, message: `${this.$t('message.error.select')}` }],
        tagColor: [{ required: true, message: `${this.$t('message.error.select')}` }],
        tagLabel: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validateLabel }],
        hoverLabel: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validateLabel }],
        order: [{ required: true, message: `${this.$t('message.error.select')}` }],
        popoverTitle: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validatePopoverTitle }],
        popoverContent: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validatePopoverContent }]
      })
      this.formEdit = reactive({
        tagPrice: '',
        woodPlan: undefined,
        bronzePlan: undefined,
        silverPlan: undefined,
        goldPlan: undefined,
        platinPlan: undefined,
        showInPartnerPortal: false,
        tagLabel: undefined,
        hoverLabel: undefined,
        isCheckbox: false,
        order: 0,
        popover: false,
        popoverTitle: undefined,
        popoverContent: undefined
      })
      this.rulesEdit = reactive({
        tagPrice: [{ required: true, message: `${this.$t('message.error.required.input')}` }],
        tagLabel: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validateLabel }],
        hoverLabel: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validateLabel }],
        order: [{ required: true, message: `${this.$t('message.error.select')}` }],
        popoverTitle: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validatePopoverTitle }],
        popoverContent: [{ required: true, message: `${this.$t('message.error.required.input')}` }, { validator: this.validatePopoverContent }]
      })
    },
    fetchPlanData () {
      api2.getAllPartnerPlans().then(response => {
        this.planData = response.data
      })
    },
    async fetchData () {
      this.loading = true
      await wpapi.getAllTags().then(response => {
        this.items = response.data
        for (var item of this.items) {
          item.planDiscounts = []
        }
      })
      this.loading = false
      this.getUniques(this.items, 'color')
      await wpapi.getAllTagTypes().then(response => {
        this.tagTypes = response.data
      })
      await api2.getAllLicenseDiscountPlan().then(response => {
        this.licensePlanDiscounts = response.data
        for (var license of this.items) {
          for (var planDiscount of this.licensePlanDiscounts.filter(discount => discount.LicenseId === license.id)) {
            license.planDiscounts.push({ planId: planDiscount.PlanId, plan: planDiscount.Plan, discount: planDiscount.Discount, dbTableId: planDiscount.Id })
          }
          license.planDiscounts = license.planDiscounts.sort((a, b) => a.planId - b.planId)
        }
      })
    },
    fillEditDiscount (price, planList) {
      const form = this.formEdit
      this.loading = true
      form.tagPrice = price
      form.woodPlan = undefined
      form.bronzePlan = undefined
      form.silverPlan = undefined
      form.goldPlan = undefined
      form.platinPlan = undefined

      for (var plan of planList) {
        switch (plan.plan) {
          case 'Holz':
            form.woodPlan = parseFloat(plan.discount)
            break
          case 'Bronze':
            form.bronzePlan = parseFloat(plan.discount)
            break
          case 'Silber':
            form.silverPlan = parseFloat(plan.discount)
            break
          case 'Gold':
            form.goldPlan = parseFloat(plan.discount)
            break
          case 'Platin':
            form.platinPlan = parseFloat(plan.discount)
            break
          default:
        }
      }
      this.loading = false
    },
    onEditLicense (record) {
      this.preFillEditLicenseId = record.id
      this.preFillEditLicensePrice = record.price
      this.preFillEditLicenseColor = record.tagColor
      this.preFillEditLicenseType = record.tagType
      this.editLicenseLabel = record.tagText
      this.fillEditDiscount(record.price, record.planDiscounts)
      this.fillPartnerParams(record)
      this.showEditTagModal = true
    },
    onDeleteSingleLicensePlan (licenseName, planId, plan) {
      this.deleteSinglePlanLicenseName = licenseName
      this.deleteSinglePlanName = plan
      this.deleteSinglePlanId = planId
      this.deleteSinglePlanconfirmationModal = true
    },
    async submitDeleteSingleLicensePlan () {
      var deletePlanParams = new FormData()
      deletePlanParams.append('id', JSON.stringify([{ id: this.deleteSinglePlanId }]))
      await api2.removeLicenseDiscountPlan(deletePlanParams).then(response => {
        console.log('License Plan Successfully deleted')
        this.closeAction('singleDelete')
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('message.request.failed'),
          description: 'Plan Rabatte konnten nicht erfolgreich gelöscht werden. Bitte versuchen Sie es erneut!' + error,
          duration: 0
        })
        this.closeAction('singleDelete')
      })
    },
    onDeleteLicense (record) {
      this.deleteLicenseName = record.tagText
      this.deleteLicenseType = record.tagType
      this.deleteLicenseId = record.id
      for (var licensePlans of record.planDiscounts) {
        this.deleteLicensePlanIdList.push({ id: licensePlans.dbTableId })
      }
      this.confirmationMessage = 'Möchten Sie die Lizenz ' + record.tagText + ' wirklich endgültig löschen? Alle Lizenzen, die noch mit VMs, Volumes, Snapshots, etc. verknüpft sind, werden ebenfalls entfernt!'
      this.confirmationModal = true
    },
    async deleteLicenseTag () {
      this.loading = true
      if (this.deleteLicensePlanIdList.length > 0) {
        var deletePlanParams = new FormData()
        deletePlanParams.append('id', JSON.stringify(this.deleteLicensePlanIdList))
        await api2.removeLicenseDiscountPlan(deletePlanParams).then(response => {
          console.log('License Plans Successfully deleted')
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('message.request.failed'),
            description: 'Plan Rabatte konnten nicht erfolgreich gelöscht werden. Bitte versuchen Sie es erneut!' + error,
            duration: 0
          })
        })
      }
      var params = new FormData()
      params.append('id', this.deleteLicenseId)
      await wpapi.deleteLicenseTag(params).then(response => {
        this.fetchData()
        this.loading = false
        this.confirmationModal = false
        this.deleteLicensePlanIdList = []
      }).catch(error => {
        this.$notifyError(error)
        this.fetchData()
        this.loading = false
        this.deleteLicensePlanIdList = []
        this.confirmationModal = false
      })
    },
    closeAction (closeString) {
      this.showAddTagModal = false
      if (closeString === 'edit') {
        this.formRefEdit.value.resetFields()
      }
      if (closeString === 'singleDelete') {
        this.deleteSinglePlanLicenseName = undefined
        this.deleteSinglePlanName = undefined
        this.deleteSinglePlanId = undefined
        this.fetchData()
      }
      this.deleteSinglePlanconfirmationModal = false
      this.deleteLicensePlanIdList = []
      this.showEditTagModal = false
      this.loading = false
    },
    submitCreateNewTag () {
      this.formRef.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.form)
        var planParams = new FormData()
        if (this.isPlanLicense) {
          const planObjectWood = { planid: this.planData.filter(plan => plan.name === 'Holz')[0].ID, discount: values.woodPlan === undefined ? -1 : values.woodPlan }
          const planObjectBronze = { planid: this.planData.filter(plan => plan.name === 'Bronze')[0].ID, discount: values.bronzePlan === undefined ? -1 : values.bronzePlan }
          const planObjectSilver = { planid: this.planData.filter(plan => plan.name === 'Silber')[0].ID, discount: values.silverPlan === undefined ? -1 : values.silverPlan }
          const planObjectGold = { planid: this.planData.filter(plan => plan.name === 'Gold')[0].ID, discount: values.goldPlan === undefined ? -1 : values.goldPlan }
          const planObjectPlatin = { planid: this.planData.filter(plan => plan.name === 'Platin')[0].ID, discount: values.platinPlan === undefined ? -1 : values.platinPlan }
          const planDiscountList = [planObjectWood, planObjectBronze, planObjectSilver, planObjectGold, planObjectPlatin]
          planParams.append('tagPlanList', JSON.stringify(planDiscountList))
        }
        var createLicenseJsonData = [{
          text: values.tagName,
          price: values.tagPrice,
          tagType: values.tagType,
          label: values.tagLabel,
          hoverLabel: values.hoverLabel,
          showOnPartner: values.showInPartnerPortal,
          isCheckbox: values.isCheckbox,
          order: 0, // values.order,
          popOverTitle: values.popoverTitle,
          popOverContent: values.popoverContent,
          color: values.tagColor
        }]

        var params = new FormData()
        params.append('createLicenseJsonData', JSON.stringify(createLicenseJsonData))

        await wpapi.createNewLicenceTag(params).then(async response => {
          planParams.append('licenseId', response.data.id)
          if (this.isPlanLicense) {
            await api2.insertUpdateLicenseDiscountPlan(planParams).then(response => {
            }).catch(error => {
              console.log(error)
              this.$notification.error({
                message: this.$t('message.request.failed'),
                description: 'Plan Rabatte konnten für die erstellte Lizenz nicht gesetzt werden. Bitte fügen Sie die geplanten Rabatte manuell per Edit Button hinzu!' + error,
                duration: 0
              })
            })
          }
        }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.fetchData()
          this.closeAction()
        })
      })
    },
    submitEditTag () {
      this.formRefEdit.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formEdit)
        if ((values.tagPrice !== this.preFillEditLicensePrice || values.tagLabel !== this.preFillEditTagLabel || values.hoverLabel !== this.preFillEditHoverLabel || values.showInPartnerPortal !== this.preFillEditShowInPartnerPortal || values.isCheckbox !== this.preFillEditIsCheckbox || values.popoverTitle !== this.preFillEditPopoverTitle || values.popoverContent !== this.preFillEditPopoverContent) && this.preFillEditLicenseId) {
          var updateLicenseJsonData = [{
            id: this.preFillEditLicenseId,
            text: this.editLicenseLabel,
            price: values.tagPrice,
            tagType: this.preFillEditLicenseType,
            label: values.tagLabel,
            hoverLabel: values.hoverLabel,
            showOnPartner: values.showInPartnerPortal,
            isCheckbox: values.isCheckbox,
            order: 0, // values.order,
            popOverTitle: values.popoverTitle,
            popOverContent: values.popoverContent,
            color: this.preFillEditLicenseColor
          }]

          var params = new FormData()
          params.append('updateLicenseJsonData', JSON.stringify(updateLicenseJsonData))

          await wpapi.updateLicenceTagPrice(params).then(response => {
            this.fetchData()
          }).catch(error => {
            this.$notification.error({
              message: this.$t('message.request.failed'),
              description: 'Der Lizenzpreis konnte nicht aktualisiert werden! Bitte versuchen Sie es erneut' + error,
              duration: 0
            })
          })
        }
        var planParams = new FormData()
        const planObjectWood = { planid: this.planData.filter(plan => plan.name === 'Holz')[0].ID, discount: (values.woodPlan === undefined || values.woodPlan === null) ? -1 : values.woodPlan }
        const planObjectBronze = { planid: this.planData.filter(plan => plan.name === 'Bronze')[0].ID, discount: (values.bronzePlan === undefined || values.bronzePlan === null) ? -1 : values.bronzePlan }
        const planObjectSilver = { planid: this.planData.filter(plan => plan.name === 'Silber')[0].ID, discount: (values.silverPlan === undefined || values.silverPlan === null) ? -1 : values.silverPlan }
        const planObjectGold = { planid: this.planData.filter(plan => plan.name === 'Gold')[0].ID, discount: (values.goldPlan === undefined || values.goldPlan === null) ? -1 : values.goldPlan }
        const planObjectPlatin = { planid: this.planData.filter(plan => plan.name === 'Platin')[0].ID, discount: (values.platinPlan === undefined || values.platinPlan === null) ? -1 : values.platinPlan }
        const planDiscountList = [planObjectWood, planObjectBronze, planObjectSilver, planObjectGold, planObjectPlatin]
        planParams.append('licenseId', this.preFillEditLicenseId)
        planParams.append('tagPlanList', JSON.stringify(planDiscountList))
        await api2.insertUpdateLicenseDiscountPlan(planParams).then(response => {
          console.log('License Successfully updated')
          this.closeAction()
          this.fetchData()
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('message.request.failed'),
            description: 'Plan Rabatte konnten für die ausgewählte Lizenz nicht gesetzt werden. Bitte fügen Sie die geplanten Rabatte manuell per Edit Button hinzu!' + error,
            duration: 0
          })
        })
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    handleSearch (selectedKeys, confirm, dataIndex) {
      confirm()
      this.state.searchText = selectedKeys[0]
      this.state.searchedColumn = dataIndex
    },
    handleReset (clearFilters) {
      clearFilters()
      this.state.searchText = ''
    },
    getUniques (object, tagAttribute) {
      switch (tagAttribute) {
        case 'color':
          var filteredColors = []
          for (var color in object) {
            if (!filteredColors.includes(object[color].tagColor)) {
              filteredColors.push(object[color].tagColor)
            }
          }
          this.uniqueTagColors = filteredColors
          break
        case 'type':
          var filteredTypes = []
          for (var type in object) {
            if (!filteredTypes.includes(object[type].tagType)) {
              filteredTypes.push(object[type].tagType)
            }
          }
          this.uniqueTagTypes = filteredTypes
          break
      }
    },
    addItem () {
      const form = document.getElementById('newColor')
      if (form.value === '' && !form.value.startsWith('#')) {
        return
      }
      this.uniqueTagColors.push(form.value)
    },
    fillPartnerParams (record) {
      // TODO add check for not callng the edit API when no values changed!!
      console.log(record)
      this.preFillEditShowInPartnerPortal = record.showOnPartner
      this.preFillEditTagLabel = record.label
      this.preFillEditHoverLabel = record.hoverLabel
      this.preFillEditIsCheckbox = record.checkbox
      this.preFillEditPopover = record.popover
      this.preFillEditPopoverContent = record.popoverContent
      this.preFillEditPopoverTitle = record.popoverTitle

      this.formEdit.showInPartnerPortal = record.showOnPartner
      this.formEdit.tagLabel = record.label === null ? undefined : record.label
      this.formEdit.hoverLabel = record.label === null ? undefined : record.hoverLabel
      this.formEdit.order = record.order
      this.formEdit.isCheckbox = record.checkbox
      this.formEdit.popoverContent = record.popoverContent
      this.formEdit.popoverTitle = record.popoverTitle
      if (record.popoverContent === null && record.popoverTitle === null) {
        this.formEdit.popover = false
      } else {
        this.formEdit.popover = true
      }
    },
    setPartnerLabels (type, e) {
      if (type === 'add') {
        if (this.form.showInPartnerPortal) {
          console.log(e)
          this.form.tagLabel = this.form.tagName
          this.form.hoverLabel = this.form.tagName
        }
      }
      // TODO only set this if tagLabel or hoverlabel = nil
      if (type === 'edit') {
        console.log(e)
        this.formEdit.tagLabel = this.formEdit.tagName
        this.formEdit.hoverLabel = this.formEdit.tagName
      }
    },
    setPopoverData (type, e) {
      console.log('popoverBool', e)
      if (e === false) {
        if (type === 'add') {
          this.form.popoverTitle = null
          this.form.popoverContent = null
        }
        if (type === 'edit') {
          this.formEdit.popoverTitle = null
          this.formEdit.popoverContent = null
        }
      }
      console.log(this.formEdit.popoverTitle)
      console.log(this.formEdit.popoverContent)
    },
    checkForIsCheckbox (type, e) {
      console.log(e)
      if (type === 'add') {
        if (e === 'Software per Core' || e === 'Hardware') {
          this.form.isCheckbox = true
        } else {
          this.form.isCheckbox = false
        }
      }
      if (type === 'edit') {
        if (this.preFillEditLicenseType === 'Software per Core' || this.preFillEditLicenseType === 'Hardware') {
          this.formEdit.isCheckbox = true
        } else {
          this.formEdit.isCheckbox = false
        }
      }
    },
    validateLabel (rule, value) {
      const errorMessage = 'Das Label darf maximal 50 Zeichen enthalten!'
      if (value.length < 51 || value === undefined) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validatePopoverTitle (rule, value) {
      const errorMessage = 'Der Popover Titel darf maximal 50 Zeichen enthalten!'
      if (value.length < 51 || value === undefined) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validatePopoverContent (rule, value) {
      const errorMessage = 'Der Popover Content darf maximal 200 Zeichen enthalten!'
      if (value.length < 201 || value === undefined) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    }
  }
}
</script>
<style scoped>
  .tooltip-icon {
    color: rgba(0,0,0,.45);
  }
  .popoverLabel {
    margin-right: 5px;
  }
</style>
